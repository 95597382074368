#final_report {
    font-family: HelveticaNeueCyr;
    font-size: 16px;
    padding: 60px 15px;
    h1 {
        font-size: 2.2em;
        text-align: center;
        margin: 0.5em 1em;
    }
    table {
        caption {
            text-align: center;
            margin: 15px 0;
            font-size: 26px;
        }
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1rem;
        color: #212529;
        border: 1px solid #dee2e6;
        thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
        }
        tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, .05);
        }
        td,
        th {
            border: 1px solid #dee2e6;
            padding: .75rem;
            vertical-align: top;
            text-align: center;
        }
    }
    .table_wrapp {
        overflow: scroll;
        width: 100%;
    }
    .profit_block {
        font-weight: bold;
        font-size: 20px;
        margin: 30px 0;
    }
    form {
        display: flex;
        flex-flow: column;
        align-items: center;
        legend {
            color: #fff;
            text-shadow: -7px 0px 7px black, 7px 0px 7px black, 0px 7px 7px black, 0px -7px 7px black;
            font-size: 35px;
            text-align: center;
            margin-top: 25px;
        }
        input {
            border: none;
            border-radius: 14px;
            box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.75);
            text-align: center;
            height: 60px;
            font-size: 26px;
            margin: 30px;
            width: 300px;
        }
        button {
            text-decoration: none;
            background-color: #fff;
            font-weight: bold;
            color: #b30101;
            text-align: center;
            font-size: 1.3em;
            text-transform: uppercase;
            border-radius: 14px;
            height: 60px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            width: 300px;
            border: none;
            &:active {
                border: 3px solid rgb(179, 1, 1);
            }
        }
    }
}