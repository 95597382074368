#turn {
    font-family: HelveticaNeueCyr;
    .turn_top_tabs {
        display: flex;
        margin: 90px 0 30px;
        justify-content: space-evenly;
        font-weight: bold;
        font-size: 32px;
        padding: 0;
        flex-wrap: wrap;
        .turn_top_tab {
            position: relative;
            list-style: none;
            margin: 5px 10px;
            span {
                background: red;
                font-size: 18px;
                width: 25px;
                display: flex;
                height: 25px;
                justify-content: center;
                align-items: center;
                border-radius: 50%;
                color: #fff;
                position: absolute;
                top: -5px;
                right: -25px;
            }
        }
        a {
            color: #000;
            text-decoration: none;
        }
        .selected_tab {
            color: rgb(179, 1, 1);
            text-decoration: underline;
        }
    }
    h1 {
        margin: 70px 0 10px;
        text-align: center;
        font-size: 40px;
    }
    .not_turn {
        text-align: center;
        font-size: 28px;
        margin: 0 10px;
    }
    .turn_items {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    .btn_turn {
        position: fixed;
        bottom: 10px;
        display: flex;
        justify-content: space-evenly;
        width: 100%;
        flex-wrap: wrap;
        a {
            background-color: white;
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
            width: 235px;
            font-weight: bold;
            font-size: 22px;
            /* padding: 9px; */
            border: none;
            /* display: block; */
            margin: 7px;
            text-decoration: none;
            color: #000;
            text-align: center;
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;
            box-sizing: border-box;
            box-sizing: border-box;
            &:active {
                border: 3px solid rgb(179, 1, 1);
            }
        }
    }
    @media (max-width: 550px) {
        .turn_items {
            grid-template-columns: 1fr 1fr;
        }
    }
}