#add_phone_gender_age {
    position: absolute;
    top: 0;
    background: #fff;
    width: auto;
    min-height: 100%;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
    height: 1000%;
    h3 {
        text-align: center;
        font-size: 32px;
        margin: 25px 10px 22px;
    }
    .input_phone ,
    .input_name{
        border: none;
        border-radius: 14px;
        box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        text-align: center;
        height: 55px;
        /* line-height: 33px; */
        font-size: 30px;
        /* font-family: myMedium; */
        /* margin-top: 30px; */
        width: 100%;
    }
    .gender {
        
        .gender_boxes {
            display: flex;
            justify-content: space-around;
            
            input {
                display: none;
            }
            .box {
                box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                background: transparent;
                width: 150px;
                height: 50px;
                border-radius: 15px;
                font-weight: bold;
                font-size: 20px;
                text-align: center;
                /* line-height: 48px; */
                display: flex;
                justify-content: center;
                align-items: center;
                // padding: 0 20px;
                box-sizing: border-box;
            }
            input:checked ~ .box {
                border: 3px solid rgb(179, 1, 1);
            }
        }
        
    }
    .age {
        display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
        input {
            display: none;
        }
        .agebricks {
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            background: transparent;
            width: 140px;
            height: 50px;
            border-radius: 15px;
            font-weight: bold;
            font-size: 20px;
            text-align: center;
            /* line-height: 48px; */
            display: flex;
            justify-content: center;
            align-items: center;
            // padding: 0 20px;
            margin-bottom: 20px;
            box-sizing: border-box;
        }
        input:checked ~ .agebricks {
            border: 3px solid rgb(179, 1, 1);
        }
    }
    .bnt_block {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        button {
            background-color: white;
    border-radius: 14px;
    box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
    width: 198px;
    font-weight: bold;
    font-size: 24px;
    padding: 15px;
    border: none;
    display: block;
    margin: 15px;
        }
    }
}