.turn_item_preview {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);
    .modal_window {
        background-color: #fff;
        width: 500px;
        /* max-width: 100%; */
        border-radius: 14px;
        height: 90%;
        padding: 20px;
        box-sizing: border-box;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: 25%;
        overflow: scroll;
        position: relative;
        h2 {
            text-align: center;
            font-size: 32px;
            margin: 0 10px 22px;
            span {
                color: rgb(179, 1, 1);
            }
        }
        .phone_block {
            display: grid;
            grid-template-columns: 60% 1fr;
            margin: 0px 0 30px;
            align-items: center;
            font-weight: bold;
            .phone {
                font-size: 22px;
                display: flex;
                align-items: center;
            }
            .historyIcon {
                display: block;
                width: 32px;
            }
            .age {
                font-size: 22px;
                text-align: right;
            }
            .addPhone {
                border: 3px solid red;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                background: transparent;
                position: relative;
                margin-right: 10px;
                font-size: 36px;
                margin-left: auto;
            }
        }
        .serv_list {
            display: grid;
            grid-template-columns: 85% 15%;
            margin-bottom: 40px;
            .serv_name {
                margin: 3px 0;
                font-size: 20px;
            }
            .serv_sum {
                margin: 3px 0;
                font-size: 20px;
                text-align: center;
            }
        }
        .client_name_block {
            margin-bottom: 50px;
            .name_list {
                display: flex;
                flex-wrap: wrap;
                label {
                    input {
                        display: none;
                    }
                    input:checked~.name {
                        border: 3px solid rgb(179, 1, 1);
                    }
                }
                .name {
                    font-size: 20px;
                    border-radius: 14px;
                    background-color: white;
                    box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                    margin: 0 15px 15px 0;
                    padding: 0 10px;
                    height: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    box-sizing: border-box;
                }
            }
            .new_name {
                display: flex;
                input {
                    border: none;
                    border-radius: 14px;
                    box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                    text-align: center;
                    height: 50px;
                    font-size: 26px;
                    width: 100%;
                }
                .plus {
                    font-size: 27px;
                    border-radius: 14px;
                    background-color: white;
                    box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                    height: 50px;
                    width: 50px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    flex-shrink: 0;
                    margin-left: 15px;
                    border: none;
                    &:active {
                        border: 3px solid rgb(179, 1, 1);
                    }
                }
            }
        }
        .btn_block {
            .yes,
            .any_client,
            .error,
            .no {
                text-align: center;
                height: 60PX;
                width: 100%;
                font-size: 26px;
                border-radius: 14px;
                background-color: white;
                box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                margin-bottom: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                box-sizing: border-box;
                &:active {
                    border: 3px solid rgb(179, 1, 1);
                }
            }
            .wrapper {
                display: flex;
                .error {
                    margin-left: 15px;
                    width: 45%;
                }
            }
        }
    }
}