.turn_item {
    margin: 30px auto;
    width: 150px;
    height: 150px;
    border: 3px solid #b30101;
    border-radius: 14px;
    background-color: white;
    box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
    box-sizing: border-box;
    h2 {
        text-align: center;
        margin: 0;
        font-size: 20px;
    }
    .turn_num{
        text-align: center;
        font-size: 60px;

    }
    .time {
        text-align: center;
        font-size: 25px;

        color: red;
    }
}