#order_completed {
    font-family: HelveticaNeueCyr;
    padding: 60px 15px;
    h1 {
        font-size: 36px;
        text-align: center;
        span {
            color: rgb(179, 1, 1);
        }
    }
    .customer_name {
        font-size: 44px;
        /* font-family: myBold; */
        text-align: center;
        /* margin-top: 4px; */
        font-weight: bold;
    }
    .gift_block {
        h2 {
            margin: 15px 0;
            font-size: 40px;
            text-align: center;
        }
        img {
            display: block;
            width: 60%;
            margin: auto;
        }
        width: 85%;
        margin: 45px auto;
        border: 3px solid #b30101;
        border-radius: 14px;
        box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
    }
    .sms_block {
        h2 {
            margin: 15px 0;
            font-size: 40px;
            text-align: center;
        }
        width: 85%;
        margin: 45px auto;
        border: 3px solid #b30101;
        border-radius: 14px;
        box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        .sms_options {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            max-width: 820px;
            margin: auto;
            input {
                display: none;
            }
            input:checked~.option {
                border: 3px solid #b30101;
                box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
            }
            .option {
                font-size: 24px;
                /* font-family: myBold; */
                font-weight: bold;
                text-align: center;
                width: 180px;
                border-radius: 14px;
                box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
                padding: 20px 0;
                margin: 15px;
                background-color: #fff;
                box-sizing: border-box;
            }
        }
    }
    button {
        font-size: 32px;
        /* font-family: myBold; */
        text-align: center;
        font-weight: bold;
        width: 270px;
        border-radius: 14px;
        box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        padding: 20px;
        background-color: #fff;
        margin: 29px auto;
        border: none;
        display: block;
        
        &:active {
            border: 3px solid #b30101;
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
        }
    }
    
}