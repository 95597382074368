.login {
    font-family: HelveticaNeueCyr;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    .login_block {
        width: 320px;
        padding: 10px;
        box-sizing: border-box;
        legend {
            font-weight: bold;
            font-size: 26px;
            text-align: center;
        }

        footer {
            text-align: center;
        }
    }
}