#order_in_progress {
    font-family: HelveticaNeueCyr;
    padding: 60px 15px;
    .top_block {
        display: grid;
        align-items: center;
        grid-template-columns: 1fr 1fr 1fr;
        text-align: center;
        margin-bottom: 15px;
        .time {
            font-size: 35px;
        }
        .customer_data {
            font-size: 35px;
            span {
                font-size: 20px;
            }
        }
        .turn_num {
            font-weight: bold;
            font-size: 55px;
            color: red;
        }
    }
    .order_services {
        .order_serv {
            border: 3px solid #b30101;
            border-radius: 14px;
            background-color: white;
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
            margin-bottom: 10px;
            display: grid;
            padding: 10px;
            grid-template-columns: 5fr 1fr 1fr;
            .name {
                font-size: 22px;
                font-weight: bold;
            }
            .price {
                font-size: 22px;
                font-weight: bold;
                text-align: center;
            }
            .delete_order_serv {
                border-width: 2px;
                border-color: #b30101;
                border-style: solid;
                border-radius: 14px;
                background-color: white;
                font-weight: bold;
            }
        }
    }
    .allsum_block {
        font-size: 26px;
        font-weight: bold;
        display: flex;
        justify-content: space-between;
        padding: 25px 15px;
        span {
            color: #b30101;
        }
    }
    .btn_list {
        display: flex;
        flex-direction: column;
        align-items: center;
        .plus_order_service,
        .order_finish,
        .other,
        .cartoon,
        .customer_go_away,
        .no_money {
            text-align: center;
            height: 55px;
            display: flex;
            width: 320px;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            border-radius: 14px;
            background-color: white;
            font-weight: bold;
            padding: 15px;
            margin-bottom: 15px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
            &:active {
                border: 3px solid #b30101;
            }
        }
        .other {
            border: 3px solid #b30101;
        }

        .cartoon {
            flex-wrap: wrap;
            height: 120px;
            &:active {
                border: none;
            }
            input {
                display: none;
            }
            input:checked ~ .age {
                border: 3px solid #b30101;
            }
            .age {
                border: 3px solid #000;
                margin: 0 10px;
                width: 70px;
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 13px;
                &:active {
                    border: 3px solid #b30101;
                }
            }
        }
    }
    .plus_order_service_window {
        .serv_block {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .serv {
                border-radius: 14px;
                background-color: white;
                box-shadow: 0px 0px 23.3px 0.7px rgba(0, 0, 0, 0.2);
                width: 250px;
                height: 95px;
                text-align: center;
                font-size: 24px;
                margin: 10px;
                display: flex;
                justify-content: space-evenly;
                align-items: center;
                font-weight: bold;
                box-sizing: border-box;

                .serv_name {
                    width: 71%;
                }
                .serv_price {
                    width: 23%;
                    font-size: 39px;
                    letter-spacing: -3px;
                    &::first-letter {
                        color: #b30101;
                    }
                }
            }
            input {
                display: none;
            }
            input:checked ~ .serv {
                border: 3px solid rgb(179, 1, 1);
                color: #b30101;
            }
            input:disabled ~ .serv {
                color: rgb(159, 159, 159);
                border: none;
                .serv_price {
                    &::first-letter {
                        color: rgb(159, 159, 159);
                    }
                }
            }
        }
    }
    .plus_order_service_window {
        position: fixed;
        top: 0;
        left: 0;
        background: #fff;
        height: 100vh;
        width: 100vw;
        overflow: scroll;
        h3 {
            text-align: center;
            font-size: 28px;
        }
        .btn_block_plus_order_service {
            display: flex;
            justify-content: space-evenly;
            margin: 25px;
            button {
                background-color: white;
                border-radius: 14px;
                box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
                width: 245px;
                font-weight: bold;
                font-size: 24px;
                padding: 15px;
                border: none;
            }
        }
    }
    .add_phone_wrapp {
        position: fixed;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        background: rgba(0, 0, 0, 0.3);
        .modal_window {
            background-color: #fff;
            width: 500px;
            /* max-width: 100%; */
            border-radius: 14px;
            height: 90%;
            padding: 20px;
            box-sizing: border-box;
            background-repeat: no-repeat;
            background-attachment: fixed;
            background-position: center;
            background-size: 25%;
            overflow: scroll;
            position: relative;
            .modal_window-payment-wrapp {
                h3 {
                    text-align: center;
                    font-size: 26px;
                    margin: 0;
                }
                display: grid;
                grid-gap: 20px;
                grid-template-rows: auto 1fr 1fr;
                height: 100%;
                .payment-type-button {
                    border-radius: 14px;
                    border: none;
                    box-shadow: 0 0 9px 0px #727272;
                    background-color: #fff;
                    width: 170px;
                    height: 110px;
                    font-size: 12px;
                    img {
                        max-width: 40%;
                    }
                    h3 {
                        font-size: 12px;
                    }
                    &:active {
                        border: 3px solid #b30101;
                        background-color: #ffeded;
                    }
                }
            }
        }
    }
    .payment-type-button__wrapp {
        display: flex;
        justify-content: space-around;
    }
    
}
