.check_list_start {
    font-family: HelveticaNeueCyr;
    h1 {
        margin: 70px 0 10px;
        text-align: center;
        font-weight: bold;
        font-size: 40px;
    }
    .btns {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        padding: 10px;
    }
}