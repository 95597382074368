.input {
    border: none;
    border-radius: 14px;
    box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
    text-align: center;
    height: 60px;
    font-size: 26px;
    
    margin-top: 30px;
    width: 100%;
}