.notification_window_wrapper {
    width: 310px;
    height: auto;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    .notification_windows__button {
        width: 280px;
        height: 50px;
        background-color: #fff;
        margin: 10px;
        box-shadow: 0px 0px 12px 3px rgba(102, 102, 102, 0.75);
    }
    .notification_window {
        padding: 10px;
        font-family: HelveticaNeueCyr;
        font-weight: bold;
        .notification-item {
            position: relative;
            min-height: 90px;
            border-radius: 10px;
            margin-bottom: 15px;
            box-shadow: 0px 0px 12px 3px rgba(102, 102, 102, 0.75);
            display: flex;
            padding: 7px;
            align-items: center;
            padding-right: 35px;
            transition: 1s;
            animation: appear 0.25s linear;
            overflow: hidden;
            z-index: 15;
            svg {
                opacity: 0.9;
                margin-right: 12px;
                fill: currentColor;
                width: 32px;
                flex-shrink: 0;
                // transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                transition: 2s;
            }
            .svg-close {
                position: absolute;
                top: 3px;
                right: 3px;
                margin: 0;
                color: #000;
                &:active,
                &:hover {
                    color: currentColor;
                    cursor: pointer;
                }
            }
        }
        .closing {
            opacity: 0;
            width: 50%;
            height: 90px;
            margin-left: auto;
        }

        @keyframes appear {
            0% {
                width: 50%;
            }

            100% {
                width: 280px;
            }
        }

        .success {
            color: rgb(30, 70, 32);
            background-color: rgb(237, 247, 237);
            svg {
                color: #4caf50;
            }
        }
        .error {
            color: rgb(97, 26, 21);
            background-color: rgb(253, 236, 234);
            svg {
                color: rgb(97, 26, 21);
            }
        }

        .info {
            color: rgb(13, 60, 97);
            background-color: rgb(232, 244, 253);
            svg {
                color: rgb(13, 60, 97);
            }
        }
        .warning {
            color: rgb(102, 60, 0);
            background-color: rgb(255, 244, 229);
            svg {
                color: rgb(102, 60, 0);
            }
        }
    }
}
.remove-btn {
    margin-right: 0.5rem;
}

.item-enter {
    opacity: 0;
}
.item-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}
.item-exit {
    opacity: 1;
}
.item-exit-active {
    opacity: 0;
    transition: opacity 500ms ease-in;
}
