.alert_window_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    .alert_window {
        min-width: 350px;
        width: 50%;
        min-height: 450px;
        height: 50%;
        background: #fff;
        justify-content: center;
        display: flex;
        align-items: center;
        border-radius: .5em;
        font-size: 2em;
        text-align: center;
        padding: 15px;
    }
}