#customer_history {
    position: absolute;
    top: 0;
    background: #fff;
    width: 100%;
    min-height: 100%;
    left: 0;
    padding: 20px;
    box-sizing: border-box;
    height: 1000%;

    font-family: HelveticaNeueCyr;
    font-size: 16px;
    // padding: 60px 15px;
    h3 {
        font-size: 2em;
        text-align: center;
        
        margin:  0.5em 1em;
    }
    table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 1rem;
        color: #212529;
        border: 1px solid #dee2e6;
        thead th {
            vertical-align: bottom;
            border-bottom: 2px solid #dee2e6;
        }
        tbody tr:nth-of-type(odd) {
            background-color: rgba(0, 0, 0, .05);
        }
        td,
        th {
            border: 1px solid #dee2e6;
            padding: .4rem;
            
            text-align: center;
        }
    }
    .table_wrapp {
        overflow: scroll;
        width: 100%;
    
    }
    nav {
        display: flex;
        justify-content: space-between;
        margin-top: 3em;
        
        .to_next,
        .to_back {
            font-weight: bold;
    font-size: 40px;
    border: none;
    background: transparent;
    width: 90px;
        }
        .back
         {
            text-decoration: none;
                background-color: #fff;
                font-weight: bold;
                color: #b30101;
                text-align: center;
                font-size: 1.3em;
                padding: 1em;
                text-transform: uppercase;
                border-radius: 14px;
                box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
                border: none;
                &:active {
                    border: 3px solid rgb(179, 1, 1);
                }
        }
    }
    
}