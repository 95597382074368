.add_new_order {
  font-family: HelveticaNeueCyr;
  .input_phone {
  }
  .gender {
    .gender_boxes {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      input {
        display: none;
      }
      .box {
        box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        background: transparent;
        width: 110px;
        height: 50px;
        border-radius: 15px;
        font-weight: bold;
        font-size: 18px;
        text-align: center;
        /* line-height: 48px; */
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 0 20px;
        margin: 0 10px 20px;
        box-sizing: border-box;
      }
      input:checked ~ .box {
        border: 3px solid rgb(179, 1, 1);
      }
    }
  }
  .age {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    input {
      display: none;
    }
    .agebricks {
      box-shadow: 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
      background: transparent;
      width: 110px;
      height: 50px;
      border-radius: 15px;
      font-weight: bold;
      font-size: 18px;
      text-align: center;
      /* line-height: 48px; */
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 0 20px;
      margin-bottom: 20px;
      margin: 0 10px 20px;
      box-sizing: border-box;
    }
    input:checked ~ .agebricks {
      border: 3px solid rgb(179, 1, 1);
    }
  }
  .serv_block {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    .serv {
        border-radius: 14px;
        background-color: white;
        box-shadow: 0px 0px 23.3px 0.7px rgba(0, 0, 0, 0.2);
        width: 200px;
        height: 95px;
        text-align: center;
        font-size: 20px;
        margin: 10px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        font-weight: bold;
        box-sizing: border-box;

        .serv_name {
            width: 71%;
        }
        .serv_price {
            width: 23%;
            font-size: 30px;
            letter-spacing: -3px;
            &::first-letter {
                color: #b30101;
            }
        }
    }
    input {
        display: none;
    }
    input:checked ~ .serv {
        border: 3px solid rgb(179, 1, 1);
        color: #b30101;
    }
    input:disabled ~ .serv {
        color: rgb(159, 159, 159);
        border: none;
        .serv_price {
            &::first-letter {
                color: rgb(159, 159, 159);
            }
        }
    }
}
}
