.error_description_window_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(128, 128, 128, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    #error_description_window {
        min-width: 350px;
        width: 50%;
        min-height: 450px;
        height: 50%;
        background: #fff;
        
        border-radius: .5em;
        font-size: 2em;
        text-align: center;
        padding: 15px;
    }
    
    h3 {
        font-size: 24px;
        margin: 5px 10px 25px;
    }
    .error_textarea {
        border: none;
        border-radius: 14px;
        box-shadow: inset 0px 0px 13.3px 0.7px rgba(0, 0, 0, 0.2);
        font-size: 24px;
   height: 120px;
   width: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
    .bnt_block {
        display: flex;
        justify-content: space-evenly;
        flex-wrap: wrap;
        button {
            background-color: white;
            border-radius: 14px;
            box-shadow: 0px 0px 13.3px 0.7px rgba(179, 1, 1, 0.2);
            width: 198px;
            font-weight: bold;
            font-size: 24px;
            padding: 15px;
            border: none;
            display: block;
            margin: 15px;
        }
    }
}